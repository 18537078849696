<!--
 * @Author: wangwei
 * @Date: 2020-12-29 16:05:36
 * @LastEditTime: 2023-01-12 11:21:46
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: 我的余额
 * @FilePath: /java-pc/src/views/member/Header.vue
-->
<template>
    <div class="sld_my_balance" v-if="opening">
        <MemberTitle :memberTitle="L['我的余额']"></MemberTitle>
        <div class="top_info flex_row_center_center">
            <div class="total flex_column_between_start">
                <span class="title">{{ L["总金额"] }}(元)：</span>
                <div class="red flex_row_center_center">
                    <!-- <span class="" v-if="balance_info.data.rechargeSum>0">{{ Number(balance_info.data.rechargeSum).toFixed(2).toString().split('.')[0] }}</span> -->
                    <!-- <span class="fixed" v-if="Number(balance_info.data.rechargeSum) > 0">
            .{{ Number(balance_info.data.rechargeSum).toFixed(2).toString().split('.')[1] }}
          </span> -->
                    <span>{{ balance_info.data.overallBalance }}</span>
                </div>
            </div>
            <div class="total flex_column_between_start">
                <span class="title">{{ L["可用余额"] }}(元)：</span>
                <div class="red flex_row_center_center">
                    <span class="indi">{{ balance_info.data.availBalance }}</span>
                    <!-- <span class="fixed" v-if="Number(balance_info.data.balanceAvailable) > 0">
            .{{ balance_info.data.balanceAvailable }}
          </span> -->
                    <!-- <span v-if="!balance_info.data.balanceAvailable">0.00</span> -->
                </div>
            </div>
            <div class="btn_con flex_column_center_center">
                <span class="recharge pointer" @click="toRecharge">{{ L["充值"] }}</span>
                <!-- <span v-if="outputEnable" class="putout pointer" @click="toPutout">提现</span> -->
                <span class="putout pointer" @click="toPutout">提现</span>
            </div>
        </div>
        <div class="nav_list flex_row_start_center">
            <!-- <div :class="{ item: true, active: current_type == 'balance', pointer: true }" @click="change('balance')">
        消费明细
      </div> -->
            <div :class="{ item: true, active: current_type == 'recharge', pointer: true }" @click="change('recharge')">
                {{ L["充值明细"] }}
            </div>
            <!-- <div v-if="outputEnable" :class="{item:true, active:current_type=='toPutout',pointer:true}" @click="change('toPutout')">
        提现明细
      </div> -->
            <div :class="{ item: true, active: current_type == 'toPutout', pointer: true }" @click="change('toPutout')">
                提现明细
            </div>
        </div>
        <!-- 余额明细列表 -->
        <div v-if="current_type == 'balance'" class="list_container">
            <div class="top_title flex_row_center_center">
                <div class="time flex_row_center_center">{{ L["交易日期"] }}</div>
                <div class="num flex_row_center_center">{{ L["收入/支出"] }}(元)</div>
                <div class="num1 flex_row_center_center">类型</div>
                <div class="reason">{{ L["变动原因"] }}</div>
            </div>
            <div
                class="list_item flex_row_center_center"
                v-for="(balanceItem, index) in balance_list.data"
                :key="index"
            >
                <div class="time flex_row_center_center">{{ balanceItem.createTime }}</div>
                <div :class="{ num: true, green: true, flex_row_center_center: true, red: balanceItem.state == 2 }">
                    {{ balanceItem.state == 1 ? "+" : "-" }}{{ balanceItem.changeValue }}
                </div>
                <div class="num1 flex_row_center_center">{{ balanceItem.bankName }}</div>
                <div class="reason">{{ balanceItem.description }}</div>
            </div>
            <SldCommonEmpty
                tip="暂无余额明细~"
                totalHeight="587"
                totalWidth="1003"
                v-show="!balance_list.data.length"
            />
        </div>
        <!-- 充值明细 -->
        <div v-else-if="current_type == 'recharge'" class="list_container">
            <div class="top_title flex_row_center_center">
                <div class="time flex_row_center_center">{{ L["交易日期"] }}</div>
                <div class="num flex_row_center_center">{{ L["充值金额"] }}(元)</div>
                <div class="num flex_row_center_center" style="flex: 1">交易单号</div>
                <div class="reason" style="flex: 1">{{ L["状态"] }}</div>
            </div>
            <div
                class="list_item flex_row_center_center"
                v-for="(rechargeItem, index) in recharge_list.data"
                :key="index"
            >
                <div class="time flex_row_center_center">{{ rechargeItem.createTime }}</div>
                <div class="num flex_row_center_center">{{ rechargeItem.money }}</div>
                <div class="reason">{{ rechargeItem.tranNo }}</div>
                <div class="reason">{{ rechargeItem.type }}</div>
            </div>
            <SldCommonEmpty
                tip="暂无充值明细~"
                totalHeight="587"
                totalWidth="1003"
                v-show="!recharge_list.data.length"
            />
        </div>
        <!-- 提现明细 -->
        <!-- <div v-else-if="outputEnable && current_type == 'toPutout'" class="list_container"> -->
        <div v-else-if="current_type == 'toPutout'" class="list_container">
            <div class="top_title putout flex_row_start_center">
                <div class="sn">提现单号</div>
                <div class="time">申请时间</div>
                <div class="money">提现金额</div>
                <div class="extra">手续费</div>
                <div class="state">状态</div>
                <div class="reason">失败原因</div>
                <!--<div class="operate">操作</div>-->
            </div>
            <div v-for="item in output_list.data" :key="item.cashId" class="list_item putout flex_row_center_center">
                <div class="sn">{{ item.tranNo }}</div>
                <div class="time">{{ item.createTime }}</div>
                <div class="money">￥{{ item.money }}</div>
                <div class="extra">￥{{ item.tranFee }}</div>
                <div class="state">{{ item.type }}</div>
                <div class="reason" :title="item.desc && item.desc.length > 9 ? item.desc : ''">
                    {{ item.desc || "--" }}
                </div>
                <div class="operate flex_row_center_center">
                    <!-- <span @click="approval_sub(1)">审核通过</span>
          <span
            @click="approval_sub(2)">审核拒绝</span> -->
                    <!--<span @click="outputInfo(item.cashId)">查看</span>-->
                </div>
            </div>
            <SldCommonEmpty tip="暂无提现明细~" totalHeight="587" totalWidth="1003" v-show="!output_list.data.length" />
        </div>
        <!-- 分页 start -->
        <div class="flex_row_end_center sld_pagination">
            <el-pagination
                @current-change="handleCurrentChange"
                :currentPage="pageData.current"
                :page-size="pageData.pageSize"
                layout="prev, pager, next, jumper"
                :total="pageData.total"
                :hide-on-single-page="true"
            >
            </el-pagination>
        </div>
        <!-- 分页 end -->
        <div class="dialog_desc_box">
            <el-dialog title="提示" width="420px" v-model="modalVisible" @close="close_dialog" top="30vh">
                <div class="dialog_desc">您尚未设置支付密码，请设置支付密码后再进行后续操作。</div>
                <div class="dialog_btn flex_row_end_center">
                    <div class="dialog_cancle" @click="close_dialog">取消</div>
                    <div class="dialog_set" @click="set_dialog">去设置</div>
                </div>
            </el-dialog>
            <el-dialog title="输入支付密码" width="420px" v-model="pdVisible" @close="close_pd" top="30vh">
                <div v-if="!passwordErr" class="dialog_desc2">请输入支付密码以完成后续操作</div>
                <div v-else class="dialog_desc2 error">{{ passwordErr }}</div>
                <input type="password" v-model="password" class="dialog_input" placeholder="请输入平台支付密码 " />
                <div class="dialog_btn flex_row_end_center">
                    <div class="dialog_cancle" @click="close_pd">取消</div>
                    <div class="dialog_set" @click="submit_dialog">确认</div>
                </div>
            </el-dialog>
        </div>
        <!-- 审批选择弹窗 start-->
        <div class="dia_start">
            <el-dialog v-model="diaflag" title="审批" width="560px" class="dialogs">
                <div class="aftersales_box">
                    <div class="approval_box">
                        <p class="approval_tit">审批：</p>
                        <div class="approval_con">
                            <div
                                class="approval_con_1"
                                @click="approval_info(1)"
                                :class="{ approval_con_one: approval_flag }"
                            >
                                通过
                            </div>
                            <div
                                class="approval_con_2"
                                @click="approval_info(2)"
                                :class="{ approval_con_one: !approval_flag }"
                            >
                                拒绝
                            </div>
                        </div>
                    </div>
                    <div class="approval_box" style="margin-top: 20px" v-if="!approval_flag">
                        <p class="approval_tit">拒绝原因：</p>
                        <textarea name="" id="" placeholder="请输入拒绝原因"></textarea>
                    </div>
                </div>
                <template #footer>
                    <div style="width: 78px"></div>
                    <span class="dialog-footer dia_btn">
                        <el-button
                            type="primary"
                            @click="diasumber"
                            style="background-color: #f30213; border-color: #f30213"
                            >确定
                        </el-button>
                    </span>
                </template>
            </el-dialog>
        </div>
        <!-- 审批选择弹窗 end-->
        <!-- 提现提示弹窗 start-->
        <div class="dia_start">
            <el-dialog v-model="drawalflag" title="提示" width="560px" class="dialogs">
                <div class="aftersales_box aftersales_box_one">
                    <div class="dia_start_tit">邮箱：</div>
                    <div class="dia_start_con">
                        <div class="dia_start_right">
                            <input type="text" name="" id="" placeholder="请输入邮箱" class="dia_start_inp" />
                            <div class="dia_start_btn">发送</div>
                        </div>
                        <p class="dia_start_p">
                            使用提现功能需要先开通 宝户通
                            账号，请在上面输入框输入接收邮箱，收到邮件后按相关提示完成开户。
                        </p>
                    </div>
                </div>
            </el-dialog>
        </div>
        <!-- 提现提示弹窗 end-->
    </div>
</template>

<script>
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { ElMessage, ElPagination, ElMessageBox } from "element-plus";
import { getCurrentInstance, onMounted, reactive, ref } from "vue";
import MemberTitle from "../../../components/MemberTitle";
import SldCommonEmpty from "../../../components/SldCommonEmpty";
import { Encrypt, Decrypt } from "@/utils/common.js";
export default {
    name: "Balance",
    components: {
        MemberTitle,
        SldCommonEmpty,
        ElPagination
    },
    setup() {
        const router = useRouter();
        const { proxy } = getCurrentInstance();
        const L = proxy.$getCurLanguage();
        const store = useStore();
        const memberInfo = ref(store.state.memberInfo);
        const modalVisible = ref(false);
        const pdVisible = ref(false);
        const passwordErr = ref("");
        const password = ref("");
        const diaflag = ref(false); //显示审批框
        const drawalflag = ref(false); //显示提现提示框
        const approval_flag = ref(true); //审批拒绝通过切换
        const pageData = reactive({
            current: 1,
            pageSize: 20,
            total: 0
        });
        const balance_list = reactive({ data: [] });
        const recharge_list = reactive({ data: [] });
        const balance_info = reactive({ data: {} });
        const output_list = reactive({ data: [] });
        const current_type = ref("recharge"); //当前列表显示的数据类型(默认值：balance)
        const outputEnable = ref(true); //提现是否开启
        const goAccount = () => {
            router.replace("/member/openAccount");
        };
        //审批拒绝通过切换
        const approval_info = (flag) => {
            if (flag == 1) {
                approval_flag.value = true;
            } else {
                approval_flag.value = false;
            }
        };
        //审批确定按钮
        const diasumber = () => {
            diaflag.value = false;
        };
        //显示审批弹框
        const approval_sub = (flag) => {
            if (flag == 1) {
                approval_flag.value = true;
            } else {
                approval_flag.value = false;
            }
            diaflag.value = true;
        };
        const getRechargeList = () => {
            //获取充值明细列表
            var param = {};
            param.walletId = balance_info.data.walletId;
            param.tranType = 1;
            param.pageNum = pageData.current;
            param.pageSize = pageData.pageSize;
            proxy
                .$get("api/pay/wallet/record/page", param)
                .then((res) => {
                    if (res.code == 200) {
                        recharge_list.data = res.data.records;
                        pageData.total = res.data.total;
                    } else {
                        ElMessage(res.msg);
                    }
                })
                .catch(() => {
                    //异常处理
                });
        };
        const getBalanceinfo = () => {
            //获取余额信息
            proxy
                .$get("api/shopp/member/wallet")
                .then((res) => {
                    if (res.code == 200) {
                        balance_info.data = res.data;
                        getRechargeList();
                    } else {
                        ElMessage(res.msg);
                    }
                })
                .catch(() => {
                    //异常处理
                });
        };
        const getOutputList = () => {
            //获取提现明细
            var param = {};
            param.walletId = balance_info.data.walletId;
            param.tranType = 0;
            param.pageNum = pageData.current;
            param.pageSize = pageData.pageSize;
            proxy
                .$get("api/pay/wallet/record/page", param)
                .then((res) => {
                    if (res.code == 200) {
                        output_list.data = res.data.records;
                        pageData.total = res.data.total;
                    } else {
                        ElMessage(res.msg);
                    }
                })
                .catch(() => {
                    //异常处理
                });
        };
        const initList = () => {
            if (current_type.value == "balance") {
                // getBalanceList();
            } else if (current_type.value == "recharge") {
                getRechargeList();
            } else {
                getOutputList();
            }
        };
        //切换
        const change = (type) => {
            pageData.current = 1;
            current_type.value = type;
            initList();
        };
        //向前翻页
        const handlePrevCilickChange = () => {
            pageData.current--;
            initList();
        };
        //向后翻页
        const handleNextCilickChange = () => {
            pageData.current++;
            initList();
        };
        //页数改变
        const handleCurrentChange = (current) => {
            pageData.current = current;
            initList();
        };
        //去充值
        const toRecharge = () => {
            proxy.$get("api/shopp/member/bank/card/list").then((res) => {
                if (res.code == 200) {
                    bankList.data = res.data;
                    if (userType.data.memberUserType != 0 && res.data.length == 0) {
                        ElMessage.warning("请先去绑定银行卡");
                        return;
                    }
                    // 判断是否可以充值
                    if (!opening.value) {
                        ElMessageBox.confirm("尚未开通钱包，请开通钱包后进行充值提现", "提示", {
                            confirmButtonText: "立即开通钱包 >",
                            // cancelButtonText: '立即开户>',
                            confirmButtonClass: "dilog_button",
                            showCancelButton: false,
                            // cancelButtonClass: 'classDilog',
                            // type: 'warning',
                            center: true
                        })
                            .then(() => {
                                router.push("/member/AccountNow");
                                return;
                            })
                            .catch(() => {
                                // ElMessage({
                                //   type: 'info',
                                //   message: 'Delete canceled',
                                // })
                            });
                    } else {
                        router.push({
                            path: "/member/recharge"
                        });
                    }
                } else {
                    // ElMessage.warning(res.msg)
                }
            });

            // 判断是否可以充值end
            // router.push({
            //   path: '/member/recharge'
            // })
        };
        //提现
        const toPutout = () => {
            // if (!outputEnable.value) {
            //   // ElMessage.warning('提现功能已关闭')
            //   drawalflag.value = true
            // }
            proxy.$get("api/shopp/member/bank/card/list").then((res) => {
                if (res.code == 200) {
                    // ElMessage.success(res.msg)
                    // console.log(res)
                    bankList.data = res.data;
                    if (userType.data.memberUserType != 0 && res.data.length == 0) {
                        ElMessage.warning("请先去绑定银行卡");
                        pdVisible.value = false;
                        return;
                    } else {
                        if (memberInfo.value.hasPayPassword == 1) {
                            pdVisible.value = true;
                        } else {
                            modalVisible.value = true;
                        }
                    }
                } else {
                    // ElMessage.warning(res.msg)
                }
            });
        };
        //提现详情
        const outputInfo = (id) => {
            router.push({
                path: "/member/balance/info",
                query: {
                    id
                }
            });
        };
        //取消弹窗
        const close_dialog = () => {
            modalVisible.value = false;
        };
        //设置支付密码
        const set_dialog = () => {
            router.push({
                path: "/member/pwd/pay",
                query: {
                    type: "set"
                }
            });
        };
        const close_pd = () => {
            pdVisible.value = false;
            password.value = "";
            passwordErr.value = "";
        };
        const submit_dialog = () => {
            if (!password.value) {
                passwordErr.value = "请输入平台支付密码";
            } else {
                proxy
                    .$post(
                        "api/pay/wallet/valid/password",
                        {
                            uscUserId: store.state.memberInfo.uscUserId,
                            password: Encrypt(password.value)
                        },
                        "json"
                    )
                    .then((res) => {
                        if (res.code == 200) {
                            router.push("/member/balance/operate");
                        } else {
                            passwordErr.value = res.msg || "密码输入错误，请重新输入";
                        }
                    })
                    .catch(() => {
                        //异常处理
                    });
            }
        };
        const getOutputEnable = () => {
            proxy
                .$get("api/shopp/setting/getSettings", { names: "withdraw_is_enable,withdraw_alipay_is_enable" })
                .then((res) => {
                    if (res.state == 200) {
                        outputEnable.value = res.data[0] == "1" && res.data[1] == "1" ? true : false;
                    }
                });
        };
        const opening = ref(false);
        const userType = reactive({ data: {} });
        const getInfo = () => {
            let param = {};
            proxy.$get("api/shopp/member/detail", { uscUserId: store.state.memberInfo.uscUserId }).then((res) => {
                if (res.code == 200) {
                    store.commit("updateMemberInfo", res.data);
                    userType.data = res.data;
                    if (!res.data.createWallet && res.data.memberUserType == 0) {
                        opening.value = false;
                        router.push("/member/AccountNow");
                        // router.push('/member/AccountNow')
                    } else {
                        opening.value = true;
                    }
                }
            });
        };
        // 获取银行卡列表
        const bankList = reactive({ data: [] });
        const getBankInfo = async () => {
            proxy.$get("api/shopp/member/bank/card/list").then((res) => {
                if (res.code == 200) {
                    // ElMessage.success(res.msg)
                    // console.log(res)
                    bankList.data = res.data;
                } else {
                    // ElMessage.warning(res.msg)
                }
            });
        };
        onMounted(() => {
            if (!memberInfo.value.isLookWallet) {
                ElMessage.warning("暂无权限访问");
                router.push("/member/index");
                return;
            }
            getInfo();
            // goAccount();
            getBankInfo();
            // getBalanceList();
            getBalanceinfo();
            getOutputEnable();
        });
        return {
            L,
            pageData,
            handlePrevCilickChange,
            handleNextCilickChange,
            handleCurrentChange,
            userType,
            balance_list,
            change,
            bankList,
            current_type,
            recharge_list,
            getBalanceinfo,
            balance_info,
            toRecharge,
            toPutout,
            outputInfo,
            memberInfo,
            modalVisible,
            close_dialog,
            set_dialog,
            pdVisible,
            close_pd,
            passwordErr,
            submit_dialog,
            password,
            output_list,
            getOutputList,
            outputEnable,
            diaflag,
            approval_info,
            approval_flag,
            diasumber,
            drawalflag,
            approval_sub,
            opening
        };
    }
};
</script>

<style lang="scss" scoped>
@import "../../../style/balance.scss";
.classDilog {
    span {
        color: rgba(30, 119, 240, 1);
    }
}
.sld_pagination {
    margin-top: 20px;
    margin-bottom: 20px;
}
</style>
<style lang="scss">
.dilog_button {
    background-color: #fff !important;
    color: #409eff !important;
    margin: 0 auto;
    border: none;
    &:hover {
        background-color: #fff;
        color: #409eff;
        border: none;
    }
}
.sld_my_balance {
    .dialog_desc_box {
        .el-dialog {
            border-radius: 6px;
        }

        .el-dialog__title {
            color: #000000;
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: bold;
        }

        .el-icon {
            font-size: 18px;
            position: relative;
            bottom: 6px;
            left: 4px;
        }

        .el-dialog__header {
            text-align: center;
            color: #000000;
            font-size: 22px;
            font-family: Microsoft YaHei;
            font-weight: bold;
        }

        .el-dialog__body {
            padding: 0;
        }
    }
}

.sld_my_balance {
    .dia_start {
        .el-dialog__header {
            background-color: #f30213;
            padding: 11px 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .el-dialog__title {
                color: #fff;
                font-size: 14px;
            }

            .el-dialog__headerbtn {
                top: auto;
            }

            .el-dialog__headerbtn .el-dialog__close {
                color: #fff !important;
                font-size: 18px;
            }
        }

        .el-dialog {
            overflow: hidden;
            border-radius: 4px;
        }

        .el-dialog__footer {
            display: flex;
            // justify-content: center;
            align-items: center;
        }

        .aft_btn:hover {
            background-color: rgba($color: #f30213, $alpha: 0.2);
            color: #f30213;
        }

        .aft_btn:focus {
            background-color: #fff;
            color: #333333;
            border-color: #dcdfe6;
        }

        .dia_btn {
            .el-button {
                width: 134px;
                height: 36px;
            }
        }
    }
}
</style>
